
.page-id-12,
.page-id-106,
.page-id-14{
    h1 {
        color: $dark;
        font-style: normal;
        font-size: 36pt;
        letter-spacing: 1, 06pt;
        font-weight: bold;
        color: $dark;
        text-align: center;
        margin-top: 80px;
        line-height: 36pt;
    }
}