    @import url('https://fonts.googleapis.com/css?family=Varela+Round');



// $green: #03BFA5;
$dark: #1C1F29;
$lightgrey: #F8F8F8;
$darkgrey: #C5CACF;
$darkblue: #9DCDFF;

$highlight: #BCA78B;

$S:320px;
$M:768px;
$L:900px;

body,
html {


    font-family: 'Varela Round', sans-serif;

    /*#E8E1DA;*/
    line-height: 19pt;
    font-size: 11pt;
    letter-spacing: 1pt;
    min-height: 100%;
    color: #444;
    height: 100%;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}


.batch {
    width: 50px;
    height: 130px;
    background-color: $dark;
    position: fixed;
    left: 0;
    z-index: 1000;
}

.icon {
    width: 60px;
    height: 60px;
    display: block;

    margin: auto;
    margin-top: 10%;
    margin-bottom: 40px;
    background-image: url('../../img/raumgold-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

img {
    width: 100%;
    height: auto;
}


h1 {

    text-align: center;
}

h2 {
    color: $dark;
    font-style: normal;
    font-size: 36pt;
    letter-spacing: 1, 06pt;
    font-weight: bold;
    color: $dark;
    text-align: center;
    margin-top: 80px;
    line-height: 36pt;
}

h3{
    color: $dark;
    font-style: normal;
    font-size: 18pt;
    letter-spacing: 1, 06pt;
    font-weight: bold;
    color: $dark;
    margin-top: 80px;
    line-height: 26pt;
}


p {
    font-style: normal;
    font-size: 12pt;
    letter-spacing: 1pt;
    font-weight: 400;
    color: $dark;
    line-height: 23pt;
}

a {
    text-decoration: none;
    color: $highlight;
    &:visited {
        text-decoration: none;
        color: $highlight;
    }
}

#wrapper {

    width: 100%; //960px;
    margin: auto;
    background-color: #fff;

    #header {
        position: relative; // z-index: 1000;
        background-color: white;
        border-bottom: 1px solid $darkgrey;
        height: 120px;
        max-width: 1100px;
        margin: auto;
        #logo {
            background-image: url('../../img/logo-raumgold-hausverwaltung-berlin.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 200px;
            height: 100px;
            display: block;
            float: left
        }
        .menu-hauptmenu-container {
            float: right;
            margin-top: 25px;
            ul {
                padding: 0;
                li {
                    display: inline;

                    list-style: none;
                    padding: 0;
                    margin: 10px;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        font-style: normal;
                        font-size: 12pt;
                        letter-spacing: 1, 06pt;
                        font-weight: 400;
                        color: $dark;
                        text-decoration: none;
                        text-transform: uppercase;
                        &:hover {
                            color: $highlight;
                        }
                    }
                }
            }
        }
    }




    #main {
        max-width: 1100px;
        margin: auto; // padding-top: 120px;
        margin-bottom:100px;
        // margin-bottom: 100px !important;
        #stage {
            width: 100%;
            position: relative;

            #stage-overlay {
                position: absolute;

                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.2);
                h1 {
                    color: #fff;
                }

                a.btn {
                    border: 3px solid #fff;
                    padding: 15px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    color: #fff;
                    font-size: 16pt;
                    font-weight: bold;
                    margin: auto;
                    width: 220px;
                    display: block;
                    text-align: center;
                    margin-top: 50px;
                    margin-bottom: 50px;
                    -webkit-transition: all .5s;
                    -moz-transition: all .5s;
                    -ms-transition: all .5s;
                    -o-transition: all .5s;
                    transition: all .5s;
                    &:hover {
                        background-color: #fff;
                        color: $dark;
                    }
                }
            }
            img {
                width: 100%;
                height: auto;
                display: block;
            }
        }
        .leistungen {
            background-color: $lightgrey;
            padding-top: 56.25%;
            position: relative;
            width: 100%;
            a{
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }
        }
    }
}


a.btn {
    border: 3px solid $dark;
    padding: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: $dark;
    font-size: 16pt;
    font-weight: bold;
    margin: auto;
    width: 220px;
    display: block;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    &:hover {
        background-color: $dark;
        color: #fff;
    }
}



.swiper-container {
    height: 450px;
    width: 100%;
    .swiper-wrapper {
        width: 100%;
        height: 100%;
        .swiper-slide {
            text-align: center;
            img {
                height: 100%;
                width: auto;
            }
        }
    }


    .swiper-button-next {
        background-image: url('../../img/101-Arrow-02-Right-slider.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    .swiper-button-prev {

        background-image: url('../../img/101-Arrow-02-Left-Slider.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
}



#footer {
    max-width: 1100px;
    height: 400px;
    margin: auto;
    background-color: $dark;
    p {
        color: #fff;
        margin: 30px;
        font-size: 10pt;
    }
    h3 {
        color: #fff;
        margin: 30px;
        font-size:12pt;
    }
    a{
        font-size:9pt;
    }
    .menu-metamenu-container,
    .menu-footermenu-container {


        ul {
            padding: 0;
            li {
                display: block;

                list-style: none;
                padding: 0;
                margin: 30px;
                margin-top: 10px;
                margin-bottom: 10px;

                &:last-child {
                    margin-right: 0;
                }
                a {
                    font-style: normal;
                    font-size: 10pt;
                    letter-spacing: 1pt;
                    font-weight: 400;
                    color: #fff;
                    text-decoration: none;
                    text-transform: uppercase;
                    &:hover {
                        color: $highlight;
                    }
                }
            }
        }
    }
}















#map-canvas {

    height: 550px;
}










/*------------------------------------*\
    SIDEBAR
\*------------------------------------*/

.overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    z-index: 100;

    background-color: rgb(0, 0, 0);
    /* RGBa with 0.6 opacity */
    background-color: rgba(0, 0, 0, 0.6);
    /* For IE 5.5 - 7*/
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000);
    /* For IE 8*/
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000)";
}

.btn-primary {
    position: relative;
    display: none;
    width: 40px;
    height: 40px;
    border: none !important;

    float: right;
    margin-top: 25px;

    background-image: url('../../img/menu.svg');
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
}

.btn-close {
    position: absolute;
    top: 50px;
    right: 10%;
    width: 35px;
    height: 35px;
    /* margin-top: 30px; */
    display: block;
    cursor: pointer;
    background-image: url(../../img/close.svg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
}





.sidebar.right {
    top: 0;
    right: -30%;
    bottom: 0;
    z-index: 150;
    width: 270px;

    background: #fff;
    ul {

        padding: 0;
        list-style: none;
        li {
            a {
                color: $dark !important;
            }
        }
    }
}

.sidebar.right {
    width: 30%;
}

.sidebars>.sidebar {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.64);
    position: fixed;
    color: white;
    padding: 30px;
    font-size: 2em;
    text-align: center;
}


.seperator {
    text-align: left;
    width: 100%;
    border:1px solid $dark;
    display: block;
    margin: 20px;
}

.sidebar {
    ul {
        li {
            text-align: left;
            margin: 20px;
            a:hover {
                opacity: 0.5;
            }
        }
    }
}

.sidebar-categories {
    margin-top: 150px;
}
 


@media only screen and (max-width:1024px) {

    .btn-primary {
        display: block;
    }
    .page-menu{
        display: none;
    }

    .sidebar-menu{display:block;}
    .kontakt-wrapper {
        .kontakt{
            .col{    margin-bottom: 20px;}
        }
        .col {
        
            width: 100%;
            margin:0;
            padding:0;
        }
    }
}

    @media only screen and (max-width:1200px) {
        #wrapper {
            width: 90%;
        }
    }
    /*------------------------------------*\
    FORM
\*------------------------------------*/
    .wpcf7 {}


    span.wpcf7-not-valid-tip {
        display: none !important;
    }

    span.your-name,
    span.your-email {
        float: left;
        width: 100%;
    }


    .kontakt {
        padding-top: 10px !important;
        p {
            margin: 0;
        }
    }

    span.your-message {
        width: 100%;

        textarea {
            margin-top: 15px;
            width: 100%;
        }
    }

    div.wpcf7-response-output,
    div.wpcf7-validation-errors {
        display: none !important;
    }

    input[type="text"],
    input[type="email"],
    textarea {
        width: 100%;
        outline: none;
        border: none;
        background-color: #fff;
        border-bottom: 3px solid $dark;
        color: #000;
    }

    .full {
        width: 100%;
        display: block;
        clear: both;
        padding-top: 20px;
    }


    label {
        color: $dark;
        width: 30%;
        float: left;

        font-family: 'Lato', sans-serif;
        line-height: 19pt;

        font-style: normal;
        font-size: 15pt;
        letter-spacing: 1pt;
        min-height: 100%;

        height: 100%;
    }

    input[type="submit"] {
        background-color: transparent;
        border: 3px solid $dark;
        color: $dark;
        padding: 6px;
        padding-left: 20px;
        padding-right: 20px;
        font-weight: 700;
    }

    textarea {
        border: 3px solid $dark;
        resize: none;
    }